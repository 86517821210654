import { BrainSearchMessageType } from "@/brain-search/internal/__generated__/BrainSearchPage_PaginationQuery.graphql"
import { isDiscoDomain } from "@/core/route/util/routeUtils"
import { GlobalID } from "@/relay/RelayTypes"
import { displaySuccessToast } from "@components/toast/ToastProvider"

export type AiApiGenerateTextResponseType =
  | "title"
  | "description"
  | "organization_description"

namespace PublicAiApi {
  export async function generatePublicBrainSearchResponse(
    {
      brainSearchId,
      messageText,
      type,
    }: {
      brainSearchId: GlobalID
      messageText: string
      type: BrainSearchMessageType
    },
    { signal }: { signal?: AbortSignal } = {}
  ) {
    const response = await fetch(
      isDiscoDomain(window.location.host)
        ? `${GRAPHQL_URL}public-ai/generate/public-brain-search`
        : "/public-ai/generate/public-brain-search",
      {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          brainSearchId,
          messageText,
          type,
        }),
        signal,
      }
    )

    return response
  }

  export async function generateOrganizationDescription(
    { websiteUrl }: { websiteUrl: string },
    { signal }: { signal?: AbortSignal } = {}
  ) {
    displaySuccessToast({
      message: "Fetching webpage content can take up to 45 seconds, hang tight!",
    })
    const response = await fetch(
      isDiscoDomain(window.location.host)
        ? `${GRAPHQL_URL}public-ai/generate/organization/description`
        : "/public-ai/generate/organization/description",
      {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ websiteUrl }),
        signal,
      }
    )

    return response
  }
}

export default PublicAiApi
