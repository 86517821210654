import { useState } from "react"

function useSteps<T>({ initialStep, steps }: { initialStep: T; steps: T[] }) {
  const [currentStep, setCurrentStep] = useState<T>(initialStep)

  function getCurrentStepIndex(step: T) {
    return steps.indexOf(step)
  }

  function goToNextStep() {
    if (!hasNextStep()) return

    setCurrentStep((prev) => {
      const currentIndex = getCurrentStepIndex(prev)
      return steps[currentIndex + 1]
    })
  }

  function goToPreviousStep() {
    if (!hasPreviousStep()) return

    setCurrentStep((prev) => {
      const currentIndex = getCurrentStepIndex(prev)
      return steps[currentIndex - 1]
    })
  }

  function hasNextStep() {
    return currentStep !== steps[steps.length - 1]
  }

  function hasPreviousStep() {
    return currentStep !== steps[0]
  }

  function goToStep(step: T) {
    setCurrentStep(step)
  }

  return {
    currentStep,
    goToStep,
    goToNextStep,
    getCurrentStepIndex,
    goToPreviousStep,
    hasNextStep,
    hasPreviousStep,
  }
}

export default useSteps
