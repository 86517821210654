import Relay from "@/relay/relayUtils"
import { useCanTriggerAIPostSuggestionQuery } from "@components/ai/hooks/__generated__/useCanTriggerAIPostSuggestionQuery.graphql"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { graphql, useLazyLoadQuery } from "react-relay"

function useCanTriggerAIPostSuggestion({ feedId }: { feedId: string }): boolean {
  const { aiInlinePrompts } = useFeatureFlags()
  const hasEntitlement = useHasEntitlement("ai_inline_prompts")
  const { node } = useLazyLoadQuery<useCanTriggerAIPostSuggestionQuery>(
    graphql`
      query useCanTriggerAIPostSuggestionQuery($feedId: ID!) {
        node(id: $feedId) {
          ... on Feed {
            __typename
            id
            viewerPermissions
          }
        }
      }
    `,
    { feedId }
  )

  if (!Relay.isNodeType(node, "Feed")) return false
  if (!hasEntitlement || !aiInlinePrompts) return false
  return node.viewerPermissions.includes("posts.create")
}

export default useCanTriggerAIPostSuggestion
