import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { sendSentryAnException } from "@/core/sentryHandler"
import { GlobalID } from "@/relay/RelayTypes"
import { CreateMessageWithAIQueryParams } from "@components/chat/channel/CreateMessageWithAIButton"
import ChatChannelMessageInput from "@components/chat/channel/message-input/ChatChannelMessageInput"
import { processDiscoUrls } from "@components/chat/util/chatUtils"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useCallback } from "react"
import { Message } from "stream-chat"
import { MessageInput, MessageToSend, useChannelActionContext } from "stream-chat-react"
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types"

interface Props {
  chatChannelId: GlobalID
  setMessageSent?: React.Dispatch<React.SetStateAction<boolean>>
}

function ChatChannelInputContent({ chatChannelId, setMessageSent }: Props) {
  const { sendMessage } = useChannelActionContext()
  const activeOrganization = useActiveOrganization()!
  const [{ aiBrId }, setParams] = useQueryParamState<CreateMessageWithAIQueryParams>()

  return (
    <MessageInput
      Input={useCallback(
        () => (
          <ChatChannelMessageInput chatChannelId={chatChannelId} />
        ),
        [chatChannelId]
      )}
      overrideSubmitHandler={async (message: MessageToSend) => {
        const customMessageData: Partial<Message<DefaultStreamChatGenerics>> = {}
        if (aiBrId) customMessageData.bot_response_id = aiBrId

        if (typeof message.text !== "string") {
          await sendMessage(message, customMessageData)
          setMessageSent?.(true)
          handleMessageSent()
          return
        }

        try {
          const processedText = await processDiscoUrls(message.text, activeOrganization)
          const newMessage = {
            ...message,
            text: processedText,
          }
          await sendMessage(newMessage, customMessageData)
          setMessageSent?.(true)
          handleMessageSent()
        } catch (error) {
          await sendMessage(message, customMessageData)
          setMessageSent?.(true)
          handleMessageSent()
          sendSentryAnException(error, {
            extra: {
              title: "processDiscoUrlsFailed",
            },
          })
        }
      }}
      grow
      additionalTextareaProps={{
        maxLength: 5000,
      }}
    />
  )

  function handleMessageSent() {
    setMessageSent?.(true)
    setParams({ aiBrId: undefined }, "replace")
  }
}

export default ChatChannelInputContent
