import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Skeleton from "@material-ui/lab/Skeleton"
import { BANNER_PADDING_TOP } from "@utils/image/imageConstants"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React from "react"

interface BannerImageProps extends TestIDProps {
  coverPhoto: string
  className?: string
}

function BannerImage({
  coverPhoto,
  testid = "BannerImage",
  className,
}: BannerImageProps) {
  const classes = useStyles()

  return (
    <div
      data-testid={testid}
      className={classNames(classes.container, className)}
      style={{
        backgroundImage: getBackgroundImageSrc(),
      }}
    />
  )

  function getBackgroundImageSrc() {
    if (!coverPhoto) return undefined
    return `url(${coverPhoto})`
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    borderTopLeftRadius: theme.measure.borderRadius.big,
    borderTopRightRadius: theme.measure.borderRadius.big,
    borderBottomRightRadius: theme.measure.borderRadius.big,
    borderBottomLeftRadius: theme.measure.borderRadius.big,
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: BANNER_PADDING_TOP,
  },
}))

export const BannerImageSkeleton: React.FC<{ className?: string }> = ({ className }) => {
  const classes = useStyles()
  return (
    <Skeleton
      className={classNames(classes.container, className)}
      variant={"rect"}
      style={{
        width: "100%",
        height: "175px",
      }}
    />
  )
}

export default BannerImage
