import ChatBotSparkles from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIText from "@components/ai/AIText"
import DiscoBanner, { DiscoBannerProps } from "@disco-ui/banner/DiscoBanner"
import classnames from "classnames"

type AIBannerProps = Omit<DiscoBannerProps, "variant"> & {
  noIcon?: boolean
}

function AIBanner({
  testid,
  message,
  buttons,
  noIcon,
  classes: propClasses,
  ...rest
}: AIBannerProps) {
  const classes = useStyles()

  return (
    <DiscoBanner
      testid={testid}
      variant={"copilot"}
      classes={{
        banner: classnames(classes.banner, propClasses?.banner),
        icon: classes.icon,
        buttons: propClasses?.buttons,
      }}
      icon={noIcon ? undefined : <ChatBotSparkles width={24} height={24} />}
      message={
        typeof message === "string" ? (
          <AIText variant={"heading-xs-700"}>{message}</AIText>
        ) : (
          message
        )
      }
      buttons={buttons}
      {...rest}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  banner: {
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    background: `${
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight
    } padding-box, ${theme.palette.aiGradient.bluePurple02} border-box`,
    border: "1px solid transparent",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
}))

export default AIBanner
