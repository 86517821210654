import { ThreadPageContentSkeleton } from "@/chat/channel/page/content/ThreadPageContent"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import AuthRequiredRoute from "@/core/route/permissioned-route/AuthRequiredRoute"
import PermissionedRoute from "@/core/route/permissioned-route/PermissionedRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MetaDetails from "@/main/page/MetaDetails"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

const ChatChannelEmpty = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "chat-channel-empty" */ "@components/chat/channel/empty/ChatChannelEmpty"
    )
)
const WebViewContent = lazyWithRetry(
  () => import(/* webpackChunkName: "web-view-content" */ "@/webview/WebViewContent")
)
const FeedPosts = lazyWithRetry(
  () => import(/* webpackChunkName: "feed-posts" */ "@/post/list/FeedPosts")
)
const CommunityEventsCalendarPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "community-events-calendar-page" */ "@/organization/events-calendar/CommunityEventsCalendarPage"
    )
)
const OccurrenceRedirectPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "occurrence-redirect-page" */ "@/occurrence/page/OccurrenceRedirectPage"
    )
)
const CommunityNonActiveStandingPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "community-non-active-standing-page" */ "@/main/page/CommunityNonActiveStandingPage"
    )
)
const CommunityDirectMessagesPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "community-direct-messages-page" */ "@/admin/community/direct-messages/CommunityDirectMessagesPage"
    )
)
const CommunityMembersPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "community-members-page" */ "@/admin/community/members/CommunityMembersPage"
    )
)
const CommunityThreadsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "community-threads-page" */ "@/admin/community/threads/CommunityThreadsPage"
    )
)
const ChatChannelPageContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "chat-channel-page-content" */ "@/chat/channel/page/content/ChatChannelPageContent"
    )
)
const DirectMessagePageContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "direct-message-page-content" */ "@/chat/channel/page/content/DirectMessagePageContent"
    )
)
const ProductListRoutes = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "product-list-routes" */ "@/admin/community/product-list/ProductListRoutes"
    )
)
const ThreadPageContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "thread-page-content" */ "@/chat/channel/page/content/ThreadPageContent"
    )
)
const CollectionPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "collection-page" */ "@/collection/page/CollectionPage")
)

export default function CommunityFlows() {
  const location = useLocation()

  const activeOrganization = useActiveOrganization()!

  const subscriptionStanding = useSubscriptionStanding()

  if (subscriptionStanding !== "active") {
    return <CommunityNonActiveStandingPage standing={subscriptionStanding} />
  }

  return (
    <MetaDetails
      metaDetailsForSharing={{
        title: activeOrganization.name,
        url: window.location.href,
        description: activeOrganization.description || "",
        coverPhoto: activeOrganization.cover || "",
      }}
      metaTitle={activeOrganization.name}
    >
      <Switch>
        {/* WebViews */}
        <PermissionedRoute
          path={ROUTE_NAMES.COMMUNITY.WEBVIEW.BOOKMARKS}
          exact
          permission={"content.read"}
          entityKey={activeOrganization}
        >
          <WebViewContent type={"bookmarks"} />
        </PermissionedRoute>

        <PermissionedRoute
          path={ROUTE_NAMES.COMMUNITY.WEBVIEW.PROFILE}
          exact
          permission={"members.read"}
          entityKey={activeOrganization}
        >
          <WebViewContent type={"profileSettings"} />
        </PermissionedRoute>

        {/* Community members list page */}
        <PermissionedRoute
          path={ROUTE_NAMES.COMMUNITY.MEMBERS.LIST}
          exact
          permission={"organization_members.read"}
          entityKey={activeOrganization}
        >
          <CommunityMembersPage />
        </PermissionedRoute>
        {/* Experience list */}
        <PermissionedRoute
          key={`route:=${ROUTE_NAMES.COMMUNITY.EXPERIENCES.ROOT}`}
          path={ROUTE_NAMES.COMMUNITY.EXPERIENCES.ROOT}
          permission={"organization_experiences.read"}
          entityKey={activeOrganization}
        >
          <ProductListRoutes />
        </PermissionedRoute>
        {/* Community Collections */}
        <PermissionedRoute
          key={`route:=${ROUTE_NAMES.COMMUNITY.COLLECTION.DETAIL}`}
          path={ROUTE_NAMES.COMMUNITY.COLLECTION.DETAIL}
          permission={"collections.read"}
          entityKey={activeOrganization}
        >
          <CollectionPage />
        </PermissionedRoute>
        {/* Global Event Calendar */}
        <PermissionedRoute
          key={`route:=${ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT}`}
          path={ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT}
          permission={"organization_events.read"}
          entityKey={activeOrganization}
        >
          <CommunityEventsCalendarPage />
        </PermissionedRoute>

        {/* Community Events */}
        <PermissionedRoute
          key={`route:=${ROUTE_NAMES.COMMUNITY.EVENTS.APP}`}
          path={ROUTE_NAMES.COMMUNITY.EVENTS.APP}
          permission={"organization_events.read"}
          entityKey={activeOrganization}
        >
          <CommunityEventsCalendarPage isCommunityEventsApp />
        </PermissionedRoute>

        <Route
          key={`route:=${ROUTE_NAMES.COMMUNITY.SHARE.OCCURRENCE.ROOT}`}
          path={ROUTE_NAMES.COMMUNITY.SHARE.OCCURRENCE.ROOT}
        >
          <OccurrenceRedirectPage />
        </Route>

        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.ROOT}`}
          path={ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.ROOT}
        >
          <CommunityDirectMessagesPage />
        </AuthRequiredRoute>
        <PermissionedRoute
          path={ROUTE_NAMES.COMMUNITY.CHAT.CHANNEL}
          entityKey={activeOrganization}
          permission={"chat.read"}
          metaTitle={`${activeOrganization.name} - Channels`}
        >
          <ChatChannelPageContent />
        </PermissionedRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_ROOT}`}
          path={ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_ROOT}
          exact
        >
          <ThreadPageContentSkeleton />
        </AuthRequiredRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_EMPTY}`}
          path={ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_EMPTY}
          exact
        >
          <ChatChannelEmpty state={"threads"} />
        </AuthRequiredRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.COMMUNITY.THREADS.DETAIL}`}
          path={ROUTE_NAMES.COMMUNITY.THREADS.DETAIL}
        >
          <ThreadPageContent />
        </AuthRequiredRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.COMMUNITY.THREADS.ROOT}`}
          path={ROUTE_NAMES.COMMUNITY.THREADS.ROOT}
        >
          <CommunityThreadsPage />
        </AuthRequiredRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT}`}
          path={ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT}
          exact
        >
          <ThreadPageContentSkeleton />
        </AuthRequiredRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_EMPTY}`}
          path={ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_EMPTY}
          exact
        >
          <ChatChannelEmpty state={"dm"} />
        </AuthRequiredRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL}`}
          path={ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL}
        >
          <DirectMessagePageContent />
        </AuthRequiredRoute>

        {/* Community Feed */}
        <PermissionedRoute
          key={`route:=${ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST}`}
          path={ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST}
          permission={"apps.read"}
          entityKey={activeOrganization}
        >
          <FeedPosts />
        </PermissionedRoute>

        <Route>{renderRedirects()}</Route>
      </Switch>
    </MetaDetails>
  )

  /** Redirects for old community-level routes we renamed */
  function renderRedirects() {
    return (
      <Switch>
        {/* Old route for community member profile */}
        <Redirect
          from={"/people/profile/:profileId"}
          to={location.pathname.replace("/people/profile", "/members")}
        />
        {/* /experiences/(all | upcoming | past) -> /experiences/courses... */}
        <Redirect
          from={"/experiences/all"}
          to={ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.ALL}
        />
        <Redirect
          from={"/experiences/upcoming"}
          to={ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.UPCOMING}
        />
        <Redirect
          from={"/experiences/past"}
          to={ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.PAST}
        />

        <Redirect to={ROUTE_NAMES.COMMUNITY.HOME.ROOT} />
      </Switch>
    )
  }
}
