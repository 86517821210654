import { BrainSearchMessageType } from "@/brain-search/internal/__generated__/BrainSearchPage_PaginationQuery.graphql"
import { isDiscoDomain } from "@/core/route/util/routeUtils"
import { GlobalID } from "@/relay/RelayTypes"

export type AiApiGenerateTextArgs = {
  organizationId: GlobalID
  productId?: GlobalID
  responseType: AiApiGenerateTextResponseType
  prompt: string
  referenceUrl?: string
  referenceEmbeddingSourceIds?: GlobalID[]
  referenceModuleContentUsageId?: GlobalID
}
export type AiApiGenerateTextResponseType = "title" | "description" | "email"
export type AiApiGeneratePostArgs = {
  feedId: GlobalID
  template?: AiApiGeneratePostTemplate
  responseType: AiApiGeneratePostResponseType
  postTitle?: string // when generating post body, optionally pass an existing title
}
export type AiApiGeneratePostTemplate = "inactive" | "default"
export type AiApiGeneratePostResponseType = "title" | "body"
export type AiApiGenerateMessageArgs = {
  channelId: string
  template: AiApiGenerateMessageTemplate
}
export type AiApiGenerateMessageTemplate = "inactive" | "default"

namespace AiApi {
  export async function generateBrainSearchResponse(
    {
      brainSearchId,
      messageText,
      type,
    }: {
      brainSearchId: GlobalID
      messageText: string
      type: BrainSearchMessageType
    },
    { signal }: { signal?: AbortSignal } = {}
  ) {
    const response = await fetch(
      isDiscoDomain(window.location.host)
        ? `${GRAPHQL_URL}ai/generate/brain-search`
        : "/ai/generate/brain-search",
      {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          brainSearchId,
          messageText,
          type,
        }),
        signal,
      }
    )

    return response
  }

  export async function generateText(
    input: AiApiGenerateTextArgs,
    { signal }: { signal?: AbortSignal } = {}
  ) {
    const response = await fetch(
      isDiscoDomain(window.location.host)
        ? `${GRAPHQL_URL}ai/generate/text`
        : "/ai/generate/text",
      {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
        signal,
      }
    )

    return response
  }

  export async function generatePost(
    input: AiApiGeneratePostArgs,
    { signal }: { signal?: AbortSignal } = {}
  ) {
    const response = await fetch(
      isDiscoDomain(window.location.host)
        ? `${GRAPHQL_URL}ai/generate/post`
        : "/ai/generate/post",
      {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
        signal,
      }
    )

    return response
  }

  export async function generateMessage(
    input: AiApiGenerateMessageArgs,
    { signal }: { signal?: AbortSignal } = {}
  ) {
    const response = await fetch(
      isDiscoDomain(window.location.host)
        ? `${GRAPHQL_URL}ai/generate/message`
        : "/ai/generate/message",
      {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
        signal,
      }
    )

    return response
  }
}

export default AiApi
